import Web3 from "web3";
import testNFT from "@/abis/testNFT";

// main
// old - 0xf51903E859ad2A677a3d3408B7F7d64350dCCB10
// new - 0xcbB69dEf6F9Fb3A5e9F2994971278CA25bd954E3
// 0x6944F6a2941c58B2D43199cE1FF4255fa34d6DA7

export default {
  async loadWeb3({ commit }) {
    if (window.ethereum) {

      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      
      commit('setHasWeb3', true);

    } else if (window.web3) {
      
      window.web3 = new Web3(window.web3.currentProvider);
      commit('setHasWeb3', true);

    } else {
      
      commit('setHasWeb3', false);

    }
  },

  async getContract({ commit  }) {
    const contract = testNFT;

    const abi = contract.abi;

    const address = '0xcbB69dEf6F9Fb3A5e9F2994971278CA25bd954E3';
    const token = new window.web3.eth.Contract(abi, address);

    if (token) {
      commit("setNftContract", token);
      commit("setNftContractAddress", token._address);
    }

    const accounts = await window.web3.eth.getAccounts();
    if (accounts) {
      commit("setUserAddress", accounts[0]);
    }

    // string public baseURI;
    // uint256 public cost = 0.05 ether;
    // uint256 public maxSupply = 10000;
    // uint256 public maxMintAmount = 20;
  },

  async mint({ state, dispatch }, payload) {
      if (!state.nftContract) {
        await dispatch('getContract')
      }

      if (state.nftContract) {
        await state.nftContract.methods
          .mint(payload.address, payload.amount)
          .send({ from: state.userAddress, value: payload.value })
          .on("transactionHash", (hash) => {
            console.log(hash);  
          });

        // dispatch('tokenData');  
      }
  },

  async getNFT({ state, commit, dispatch }, payload) {
    if (!state.nftContract) {
      await dispatch("getContract");
    }

    const tokenUri = await state.nftContract.methods.tokenURI(payload.tokenId).call();

    commit("setTokenUri", tokenUri);

    const ownerOf = await state.nftContract.methods.ownerOf(payload.tokenId).call();

    commit("setTokenOwner", ownerOf);
  },

  async withdraw({ state, dispatch }) {
    if (!state.nftContract) {
      await dispatch("getContract");
    }

    if (state.nftContract) {
      await state.nftContract.methods
        .withdraw()
        .send({ from: state.userAddress })
        .on("transactionHash", (hash) => {
          console.log(hash);
        });
    }
  },

  async getWalletOfOwner({ state, dispatch }, payload) {
    if (!state.nftContract) {
      await dispatch("getContract");
    }

    const wallet = await state.nftContract.methods.walletOfOwner(payload.address).call();

    console.log(wallet)
  },

  // walletOfOwner

  // async getK808({ commit }) {
  //   const contract = k808v1;

  //   // const networkId = await window.web3.eth.net.getId();
  //   //const networkData = contract.networks[networkId];

  //   //if (networkData) {
  //     const abi = contract.abi;
  //     //console.log(abi)
  //     const address = '0x9c00cc5d02590bc459560d0be60a6ba6403961cf';
  //     const token = new window.web3.eth.Contract(abi, address);

  //     if (token) {
  //       commit("setK808", token);
  //       commit("setK808Address", token._address);
  //     }
  //   //}

  //   const accounts = await window.web3.eth.getAccounts();
  //   if (accounts) {
  //     commit("setUserAddress", accounts[0]);
  //   }
  // },

  // async tokenData({ state, commit, dispatch }) {
  //   if (!state.k808) {
  //     await dispatch("getK808");
  //   }

  //   const totalSupply = await state.k808.methods.totalSupply().call();

  //   commit("setTotalSupply", totalSupply);

  //   const accountBalance = await state.k808.methods
  //     .balanceOf(state.userAddress)
  //     .call();

  //   commit("setAccountBalance", accountBalance);
  // },

  // async tokenNameData({ state, commit, dispatch }) {

  //   if (!state.k808) {
  //     await dispatch('getK808')
  //   }

  //   const tokenNameCount = await state.k808.methods
  //     .nameCount()
  //     .call();
  
  //   if (tokenNameCount) {
  //     commit('setTokenNameCount', tokenNameCount);
  //   }

  // },

  // async tokenDripData({ state, commit, dispatch }) {
    
  //   if (!state.k808) {
  //     await dispatch('getK808')
  //   }

  //   // const tokenDripCount = await state.k808.methods
  //   //   .tokenDripCount()
  //   //   .call();
  
  //   // if (tokenDripCount) {
  //   //   commit('setTokenDripCount', tokenDripCount);
  //   // }

  //   const tokenDripAmount = await state.k808.methods
  //     .tokenDripAmount()
  //     .call();

  //   if (tokenDripAmount) {
  //     commit('setTokenDripAmount', tokenDripAmount);
  //   }

  //   const tokenDripMax = await state.k808.methods
  //     .tokenDripMax()
  //     .call();

  //   if (tokenDripMax) {
  //     commit('setTokenDripMax', tokenDripMax);
  //   }

  // },

  // async drip({ state, dispatch }, payload) {
  //   if (!state.k808) {
  //     await dispatch('getK808')
  //   }

  //   if (state.k808) {
  //     try {

  //       await state.k808.methods
  //         .drip(payload.name)
  //         .send({ from: state.userAddress })
  //         .on("transactionHash", (hash) => {
  //           console.log(hash);  
  //         })

  //       dispatch('tokenDripData');

  //     } catch(e) {
  //       console.log(e)
  //     }
  //   }
  // },

  // async mint({ state, dispatch }, payload) {
  //   if (!state.k808) {
  //     await dispatch('getK808')
  //   }

  //   if (state.k808) {
  //     await state.k808.methods
  //       .mint(payload.address, payload.amount)
  //       .send({ from: state.userAddress })
  //       .on("transactionHash", (hash) => {
  //         console.log(hash);  
  //       });

  //     dispatch('tokenData');  
  //   }
  // },

  // async names({ state, commit, dispatch }, payload) {
  //   if (!state.k808) {
  //     await dispatch('getK808')
  //   }

  //   const name = await state.k808.methods.idToName(payload.id).call();

  //   commit("setNames", {
  //     id: payload.id,
  //     name,
  //   });

  // },

};
