export default {
    // MAIN
    hasWeb3: (state) => state.hasWeb3,

    nftContract: (state) => state.nftContract,
    nftContractAddress: (state) => state.nftContractAddress,
    
    // other

    k808: (state) => state.k808,
    k808Address: (state) => state.k808Address,
    userAddress: (state) => state.userAddress,

    totalSupply: (state) => state.totalSupply, 
    accountBalance: (state) => state.accountBalance, 

    tokenNameCount: (state) => state.tokenNameCount, 
    
    tokenDripAmount: (state) => state.tokenDripAmount, 
    tokenDripMax: (state) => state.tokenDripMax, 

    names: (state) => state.names, 
}