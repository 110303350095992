<template>
  <div class="w-11/12 xl:w-8/12 mx-auto">
    <div class="pb-2">
      <img class="rounded-xl min-w-full" src="@/assets/images/_bg/bg.jpeg" alt="" />
    </div>

    <div class="grid grid-cols-1 gap-4 mt-4 mb-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        Costume is a story of mystical heroes and crazed villains fighting their way through the land of Lemuria. This world is filled with adventures, magical items, transformative costumes, spells, illusions, adorable creatures and conniving monsters. 
        We've set out to create characters and tell stories inspired by our favorite games and brands, stories that bring together - fun, mystery, awe and adventure. 
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4 mt-4 mb-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900 text-center">
        <div>  

          <div class="grid grid-cols-3 gap-4 pb-6 mb-7 border-dashed border-b">

            <div>
              120 Matic per NFT
            </div>
            <div>
              100 Max Mint Per TXN
            </div>
            <div>
              10,000 Total Supply
            </div>

            <div class="col-span-3 text-sm">
              <div class="mb-3">
                * 150 NFT's reserved for the team, marketing and giveaways.
              </div>
              <div class="mb-3">
                * first 10 revealed, the whole set will be revealed on Dec. 1, 2021.
              </div>
              <div class="text-base text-red-500">
                *  must connect to 
                <a href="https://coinguides.org/matic-wallet-metamask-polygon/"
                target="_open">
                Polygon ( Matic ) Mainnet.
                </a>
              </div>
            </div>

          </div>
          
          <div v-if="!nftContractAddress">
            <button class="p-3 rounded-xl border-2" @click="connect">
              Connect
            </button>
          </div>
          <div v-else>
            <div>
              <input type="number" v-model="amount" class="p-3 bg-gray-700 rounded-xl"/>
            </div>
            <div>
              <button class="p-3 rounded-xl border-2 mt-6" @click="mint">
                mint
              </button>

              <!-- <button class="p-3 rounded-xl border-2 mt-6" @click="getWalletOfOwner">
                getWalletOfOwner
              </button>    

              <button class="p-3 rounded-xl border-2 mt-6" @click="withdraw">
                withdraw
              </button>    

              <div>
                <input type="number" v-model="tokenId" class="p-3 bg-gray-700 rounded-xl"/>
              </div>
              <button class="p-3 rounded-xl border-2 mt-6" @click="tokenUri">
                view uri
              </button>  -->
            </div>
          </div>
       
        </div>

        <div v-if="nftContractAddress">
          <div class="mt-6 mb-2">
            Your Wallet Address: 
            <a target="_blank" :href="'https://polygonscan.com/address/'+userAddress">
              {{ userAddress }}
            </a>
          </div>
          <div>
            NFT Contract Address: 
            <a target="_blank" :href="'https://polygonscan.com/address/'+nftContractAddress">
              {{ nftContractAddress }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="grid justify-items-center mt-10 mb-8">
      <div class="header p-6 text-white text-3xl">
        CARDS
      </div>
      <div>
        <img src="@/assets/images/_bg/header-swirl-white.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-3 pt-12 pb-12">
      <div>
        <img  class="z-0 transform -rotate-12 translate-y-4 translate-x-8 xl:translate-x-0" src="@/assets/images/cards/7.png" alt="">
      </div>
      <div>
        <img class="transform z-50" src="@/assets/images/cards/10.png" alt="">
      </div>
      <div>
        <img class="transform z-0 rotate-12 translate-y-4 -translate-x-8 xl:-translate-x-0" src="@/assets/images/cards/8.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4 mt-14 mb-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        Costume Battle Cards are a collection of 10,000 NFTs, with a built in battle point system. Each card is unique, and showcases a character, or scene from the Costume world. 
        Cards are randomly generated, each with a unique DNA code, offering levels of rarity. 
        Cards are constructed to be usable in the Battle Cards game, and [ if this collection is succesful ] upgradable through combining and/or battling. 
      </div>
    </div>

    <div class="grid justify-items-center mt-10 mb-14">
      <div class="header p-6 text-white text-3xl">
        CHARACTERISTICS
      </div>
      <div>
        <img src="@/assets/images/_bg/header-swirl-white.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-3 gap-4 mt-4 text-center">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        Hand drawn
      </div>
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        Unique, no duplicates
      </div>
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        10,000 Provably-rare Costume Battle Cards
      </div>
      <div class="p-6 col-span-3 rounded-xl text-lg text-white bg-gray-900">
        Fair Launch, fair distribution - all cards cost 0.07 ETH
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        <img src="@/assets/images/cards/95.png" alt="" />
      </div>
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        <div class="h-full bg-black text-left p-4 text-lg">
          <div>
            <strong>
            CARD DATA
            </strong>
          </div>
          <div class="mt-2">
            Rank: Special
          </div>
          <div class="mt-2">
            Character: Ender
          </div>
          <div class="mt-2">
            Strength: 24
          </div>
          <div class="mt-2">
            Speed: 2
          </div>
          <div class="mt-2">
            Defense: 4
          </div>
          <div class="mt-2">
            Stamina: 26
          </div>
          <div class="mt-2">
            Type: Magician
          </div>
        </div>
      </div>      
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        <div class="h-full bg-black text-left p-4 text-lg">
          <div>
            <strong>
            CARD GENERATOR
            </strong>
          </div>
          <div class="mt-2">
            <strong>
            Rank has 5 different options, category 5 is rarest:
            </strong>
            <ol>
              <li>1: Common</li>
              <li>2: Special</li>
              <li>3: Luminous</li>
              <li>4: Legendary</li>
              <li>5: Occult</li>
            </ol>
          </div>
          <div class="mt-2">
            <strong>
            Character has 39 different options, category 5 is rarest:
            </strong>
            <ol>
              <li>1: Creatures and Monsters ( 9 options )</li>
              <li>2: Heroes and Villains ( 14 options )</li>
              <li>3: RPG Versions ( 3 options )</li>
              <li>4: Actions ( 7 options )</li>
              <li>5: Scenes ( 6 options )</li>
            </ol>
          </div>
          <div class="mt-2">
            <strong>
            Scores are random 1-99, higher numbers are rarer: 
            </strong>
            <ol>
              <li>- Strength</li>
              <li>- Speed</li>
              <li>- Defense</li>
              <li>- Stamina</li>
            </ol>
          </div>
          <div class="mt-2">
            <strong>
            Type has 4 equal weight options: 
            </strong>
             <ol>
              <li>- Warrior</li>
              <li>- Mystic</li>
              <li>- Healer</li>
              <li>- Magician</li>
            </ol>
          </div>
        </div>
      </div>  
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        <img src="@/assets/images/_bg/animated.png" alt="" />
      </div>    
    </div>
    
    <div class="grid justify-items-center mt-10 mb-14">
      <div class="header p-6 text-white text-3xl">
        ROADMAP
      </div>
      <div>
        <img src="@/assets/images/_bg/header-swirl-white.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-4 gap-4 mt-4">
      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        20%
      </div>
      <div
        class="col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left"
      >
        Open t-shirt and merchandise store.
      </div>

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        40%
      </div>
      <div
        class="col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left"
      >
        Grant access to Manga issue #1 to token holders.
      </div>

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        60%
      </div>
      <div
        class="col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left"
      >
        Raffle 10 ( held back ) NFT's to token holders.
      </div>

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        80%
      </div>
      <div
        class="col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left"
      >
        Start development on Manga issue #2, and provide access to token holders.
      </div>

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        100%
      </div>
      <div
        class="col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left"
      >
        Migrate 
        <a class="text-green-400" href="https://battleblocks-ae0b7.web.app" target="_open">
        Battle Blocks
        </a>
         card game ( prototype ) from Nebulas to Polygon.
      </div>

    </div>

    <div class="grid justify-items-center mt-10 mb-14">
      <div class="header p-6 text-white text-3xl">
        EXTRA
      </div>
      <div>
        <img src="@/assets/images/_bg/header-swirl-white.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-1 gap-4 mt-4 mb-4">
      <div class="p-6 rounded-xl text-lg text-center text-white bg-gray-900">
        With enough funding we can continue development into the METAVERSE.
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-4 gap-4">

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        Platform Development
        <div>
          ( concept prototype )
        </div>
      </div>

      <div class="lg:col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left">
        <div class="youtube-video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/FYHheQ8sKLE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div class="p-6 rounded-xl text-lg text-white bg-gray-900">
        RPG Development 
        <div>
          ( concept art )
        </div>
      </div>

      <div class="lg:col-span-3 p-6 rounded-xl text-lg text-white bg-gray-900 text-left">
        <img src="@/assets/images/_bg/map.jpeg" alt="">
      </div>
    </div>

    <div class="grid justify-items-center mt-10 mb-10">
      <div class="header p-6 text-white text-3xl">
        TEAM
      </div>
      <div>
        <img src="@/assets/images/_bg/header-swirl-white.png" alt="">
      </div>
    </div>

    <div class="grid grid-cols-5">
      <div class="col-start-2 col-span-3">
        <img src="@/assets/images/_bg/team.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'Home',
  components: {},
  data (){
    return {
      amount: 1,
      tokenId: null
    }
  },
  computed: {
    ...mapGetters("main", [

      "hasWeb3",

      "nftContract",
      "nftContractAddress",

      // other
      "names",

      "totalSupply",
      "k808Address",

      "userAddress",
      "accountBalance",
      "tokenNameCount",
      "tokenDripAmount",
    ]),
  },
  methods: {
    ...mapActions({
      getContract: "main/getContract",
      loadWeb3: "main/loadWeb3",
      k808tokenData: "main/tokenData",

      tokenNameData: "main/tokenNameData",
      tokenDripData: "main/tokenDripData",
    }),

    async connect() {
      await this.loadWeb3();

      if (window.ethereum) {
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });

        window.ethereum.on("accountsChanged", () => {
          this.refreshK808();
        });
      }

      if (window.web3) {
        this.refreshK808();

        const networkId = await window.web3.eth.net.getId();

        if (networkId !== 56) {
          this.$store.commit("main/setHasWeb3", false);
        }
      }
    },

    async refreshK808() {
      await this.getContract();
    },

    mint() {
      let amount = this.amount;

      const value = window.web3.utils.toWei(
        (amount * 120).toString(),
        "ether"
      );

      amount = amount.toString();

      this.$store.dispatch("main/mint", {
        address: this.userAddress,
        value,
        amount,
      });
    },

    tokenUri() {

      this.$store.dispatch("main/getTokenUri", {
        tokenId: this.tokenId,
      });

    },

    getWalletOfOwner () {

      this.$store.dispatch("main/getWalletOfOwner", {
        address: this.userAddress,
      });

    },

    withdraw () {
      this.$store.dispatch("main/withdraw", {
        address: this.userAddress,
      });
    },

    getNFT () {}
  }
}
</script>

<style scoped>
.youtube-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.youtube-video-container::after {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
