import Vue from 'vue';

export default {
  setHasWeb3(state, payload) {
    state.hasWeb3 = payload;
  },

  setNftContract(state, payload) {
    state.nftContract = payload;
  },

  setNftContractAddress(state, payload) {
    state.nftContractAddress = payload;
  },


  // other

  setK808(state, payload) {
    state.k808 = payload;
  },

  setK808Vault(state, payload) {
    state.k808Vault = payload;
  },

  setK808Address(state, payload) {
    state.k808Address = payload;
  },

  setUserAddress(state, payload) {
    state.userAddress = payload;
  },

  setTotalSupply(state, payload) {
    state.totalSupply = payload;
  },

  setAccountBalance(state, payload) {
    state.accountBalance = payload;
  },

  // data

  setTokenNameCount(state, payload) {
    state.tokenNameCount = payload;
  },

  setTokenDripMax(state, payload) {
    state.tokenDripMax = payload;
  },

  setTokenDripAmount(state, payload) {
    state.tokenDripAmount = payload;
  },

  setNames(state, payload) {
    Vue.set(state.names, payload.id, payload);
  },
};
