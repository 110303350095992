<template>
  <div>
    <div class="nav">
      <div class="p-5 pt-6 w-11/12 xl:w-8/12 mx-auto">
        <div class="flex justify-between">
          <div class="brand text-white text-2xl">
            <router-link class="header" to="/">
              COSTUME
            </router-link>
          </div>

          <div class="text-white">
            <!-- <router-link class="inline-block pr-5 text-lg" to="gallery">
              Gallery
            </router-link> -->
            <a
              class="inline-block pr-5"
              href="https://twitter.com/costumestudios"
              target="_open"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
            </a>
            <a
              class="inline-block pr-5"
              href="https://www.instagram.com/costumestudios"
              target="_open"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
            </a>
            <a class="inline-block pr-5" href="https://discord.gg/Deyzj2dNKK" target="_open">
              <font-awesome-icon :icon="['fab', 'discord']" size="lg" />
            </a>

            <a href="https://opensea.io/collection/costumebattlecards" target="_open">
              <img class="w-5 -mt-1 inline" src="@/assets/images/_logo/logo_opensea.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <router-view />

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer,
  },
};
</script>

<style>
html,
body {
  background: #000;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.brand {
  font-family: "Luckiest Guy", cursive;
}

.header {
  font-family: 'Gloria Hallelujah', cursive;
}
</style>
