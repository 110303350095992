<template>
  <div class="footer text-center">
    <div class="mt-10 mb-4 p-8 w-11/12 xl:w-8/12 mx-auto text-white">
      <div class="grid grid-cols-3 gap-4">
        <div>
          <router-link to="terms">
            Terms of Use
          </router-link>
        </div>
        <div class="mx-auto">
          <img class="w-10" src="@/assets/images/_logo/logo.png" />
        </div>
        <div>
          <div class="text-white">
            <a
              class="inline-block md:pr-5 pr-3"
              href="https://twitter.com/costumestudios"
              target="_open"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
            </a>
            <a
              class="inline-block md:pr-5 pr-3"
              href="https://www.instagram.com/costumestudios"
              target="_open"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
            </a>
            <a
              class="md:pr-5 pr-0"
              href="https://discord.gg/Deyzj2dNKK"
              target="_open"
            >
              <font-awesome-icon :icon="['fab', 'discord']" size="lg" />
            </a>

            <a href="https://opensea.io/collection/costumebattlecards" target="_open">
              <img class="w-5 -mt-1 hidden md:inline-block" src="@/assets/images/_logo/logo_opensea.svg" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="mt-6 text-xs">&copy; {{ today }} Costume Studios</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      today: new Date().getFullYear(),
    };
  },
};
</script>
