export default {
    // MAIN
    hasWeb3: true,

    nftContract: null,
    nftContractAddress: null,

    // other

    k808: null,
    k808Address: null,
    userAddress: null,

    totalSupply: null, 
    accountBalance: null, 

    tokenNameCount: null,

    tokenDripAmount: null,
    tokenDripMax: null,

    names: [],
}